<template>
  <div>
    <div class="bio_holder">
      <div class="body">
        <div class="body_wrap">
          <div class="container body_child">
            <div class="card">
              <div class="card-body my_card_body container-fluid">
                <div class="text_header">
                  <!-- <div>
                    <router-link to="biodata" class="link_back">
                      <button class="btn" v-if="userAccount === 'individual'">
                        Edit Biodata
                      </button>
                      <button class="btn" v-else>
                        Edit Profile
                      </button>
                    </router-link>
                  </div> -->
                  <div class="new-button-option">
                    <router-link to="biodata" class="link_back">
                      <button
                        v-if="userAccount === 'individual'"
                        class="btn"
                        @click="bioOption = 'editbio'"
                        :class="[
                          bioOption == 'editbio' ? 'activeOptionss' : ''
                        ]"
                      >
                        Edit Biodata
                      </button>
                      <button
                        v-else
                        class="btn"
                        @click="bioOption = 'editbio'"
                        :class="[
                          bioOption == 'editbio' ? 'activeOptionss' : ''
                        ]"
                      >
                        Edit Profile
                      </button>
                    </router-link>
                    <button
                      class="btn"
                      @click="bioOption = 'security'; openChangePinModal()"
                      :class="[bioOption == 'security' ? 'activeOptionss' : '']"
                    >
                      Security settings
                    </button>
                  </div>
                </div>
                <div class="profile_img_circle">
                  <img
                    v-if="user.passportfile == '' || user.passportfile == null"
                    class="profile_img"
                    src="../assets/images/user-blue-bg.svg"
                    alt="profile"
                  />
                  <img
                    v-else
                    class="profile_img"
                    v-bind:src="user.passportfile"
                    alt=""
                  />
                  <h5 v-if="userAccount === 'individual'">
                    {{ user.firstname }} {{ user.lastname }}
                  </h5>
                  <h5 v-else>{{ user.firstname }}</h5>
                  <div class="user_address col-md-4">
                    <img
                      v-if="state"
                      src="../assets/images/map-point.svg"
                      alt="location"
                    />
                    <small v-if="city != null">{{ city.name }}</small>
                    <p v-if="state != null">{{ state.name }}</p>
                  </div>
                </div>
                <div class="nav-tab">
                  <ul class="nav nav-tabs tabs-nav" id="myTab" role="tablist">
                    <li
                      class="nav-item"
                      v-if="who_you === 'Tenant' || 'Landlord'"
                    >
                      <a
                        class="nav-link active"
                        id="biodata-tab"
                        data-toggle="tab"
                        href="#biodata"
                        role="tab"
                        aria-controls="biodata"
                        aria-selected="true"
                        >Profile details</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        (who_you === 'Tenant' || 'Landlord') &&
                          userAccount === 'individual'
                      "
                    >
                      <a
                        class="nav-link"
                        id="emergency-tab"
                        data-toggle="tab"
                        href="#emergency"
                        role="tab"
                        aria-controls="emergency"
                        aria-selected="false"
                        >In Case of Emergency</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="who_you === 'Tenant' || 'Landlord'"
                    >
                      <a
                        class="nav-link"
                        id="identification-tab"
                        data-toggle="tab"
                        href="#identification"
                        role="tab"
                        aria-controls="identification"
                        aria-selected="false"
                        >Means of Identification</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        (who_you === 'Tenant' || 'Landlord') &&
                          userAccount === 'individual'
                      "
                    >
                      <a
                        class="nav-link"
                        id="guarantor-tab"
                        data-toggle="tab"
                        href="#guarantor"
                        role="tab"
                        aria-controls="guarantor"
                        aria-selected="false"
                        >Guarantor/Indemnitor</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        (who_you === 'Tenant' || 'Landlord') &&
                          userAccount === 'individual'
                      "
                    >
                      <a
                        class="nav-link"
                        id="nextofkin-tab"
                        data-toggle="tab"
                        href="#nextofkin"
                        role="tab"
                        aria-controls="nextofkin"
                        aria-selected="false"
                        >Next of KIN</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        (who_you === 'Tenant' || 'Landlord') &&
                          userAccount === 'individual'
                      "
                    >
                      <a
                        class="nav-link"
                        id="occupation-tab"
                        data-toggle="tab"
                        href="#occupation"
                        role="tab"
                        aria-controls="occupation"
                        aria-selected="false"
                        >Occupation</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="
                        (who_you === 'Tenant' || 'Landlord') &&
                          userAccount === 'individual'
                      "
                    >
                      <a
                        class="nav-link"
                        id="financial-tab"
                        data-toggle="tab"
                        href="#financial"
                        role="tab"
                        aria-controls="financial"
                        aria-selected="false"
                        >Financial Status</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="who_you === 'Tenant' || 'Landlord'"
                    >
                      <a
                        class="nav-link"
                        id="referrer-tab"
                        data-toggle="tab"
                        href="#referrer"
                        role="tab"
                        aria-controls="referrer"
                        aria-selected="false"
                        >Referer</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <!-- Profile details -->
                    <div
                      class="tab-pane fade show active"
                      id="biodata"
                      role="tabpanel"
                      aria-labelledby="biodata-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div
                                  class=" card-body my-card-body  container-fluid"
                                >
                                  <div class="profile_details_body d-none">
                                    <div>
                                      <p v-if="userAccount === 'individual'">
                                        Full Name:
                                      </p>
                                      <p v-else>Organisation Name:</p>
                                      <p>Email:</p>
                                      <p>Phone Number:</p>
                                      <p>Current Address:</p>
                                      <p>City:</p>
                                      <p>State:</p>
                                      <p>Country:</p>
                                    </div>
                                    <div>
                                      <p v-if="user">
                                        {{ user.firstname }} {{ user.lastname }}
                                      </p>
                                      <p v-if="user">{{ user.email }}</p>
                                      <p v-if="user">{{ user.phone }}</p>
                                      <p>{{ user.address }}</p>
                                      <p v-if="city != null">{{ city.name }}</p>
                                      <p v-if="state != null">
                                        {{ state.name }}
                                      </p>
                                      <p>Nigeria</p>
                                    </div>
                                  </div>

                                  <table
                                    class="table table-borderless table-reponsive"
                                  >
                                    <tbody v-if="fulldata != null">
                                      <tr class="row_table">
                                        <th v-if="userAccount === 'individual'">
                                          Full Name:
                                        </th>
                                        <th v-else>Name:</th>
                                        <td
                                          v-if="
                                            user && userAccount === 'individual'
                                          "
                                        >
                                          {{ user.firstname }}
                                          {{ user.lastname }}
                                        </td>
                                        <td v-else>
                                          {{ user.firstname }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Email:</th>
                                        <td v-if="user">{{ user.email }}</td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Phone Number:</th>
                                        <td v-if="user">{{ user.phone }}</td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Current Address:</th>
                                        <td v-if="user">
                                          {{ user.address }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>City:</th>
                                        <td v-if="city != null">
                                          {{ city.name }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>State:</th>
                                        <td v-if="state != null">
                                          {{ state.name }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Country of residence:</th>
                                        <td>Nigeria</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Profile details -->
                    <!-- in case of emangecy contact -->
                    <div
                      class="tab-pane fade"
                      id="emergency"
                      role="tabpanel"
                      aria-labelledby="emergency-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <table
                                    class="table table-borderless table-reponsive"
                                  >
                                    <tbody
                                      v-if="fulldata.emergency_details != null"
                                    >
                                      <tr class="row_table">
                                        <th>Full Name:</th>
                                        <td v-if="fulldata.emergency_details">
                                          {{
                                            fulldata.emergency_details.fullname
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Phone Number</th>
                                        <td v-if="fulldata.emergency_details">
                                          {{ fulldata.emergency_details.phone }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td v-if="fulldata.emergency_details">
                                          {{
                                            fulldata.emergency_details.address
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Relationship:</th>
                                        <td v-if="fulldata.emergency_details">
                                          {{
                                            fulldata.emergency_details
                                              .relationship
                                          }}
                                        </td>
                                      </tr>
                                      <!-- <tr class="row_table">
                                          <th>State:</th>
                                          <td>FCT</td>
                                      </tr>
                                      <tr class="row_table">
                                          <th>Country of residence:</th>
                                          <td>Nigeria</td>
                                      </tr> -->
                                    </tbody>
                                    <h3 class="noinfo_details" v-else>
                                      No Emergency details
                                    </h3>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Incase of emangency ends -->

                    <!-- Means of Identification -->
                    <div
                      class="tab-pane fade"
                      id="identification"
                      role="tabpanel"
                      aria-labelledby="identification-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <div class="means_of_id">
                                    <h5>
                                      Document type:
                                      <span>{{ user.meansofidtype }}</span>
                                    </h5>
                                    <img
                                      :src="user.meansofidfileurl"
                                      alt="Meansofid"
                                      v-if="user.meansofidtype != null"
                                    />
                                    <h3
                                      class="noinfo_details"
                                      v-if="user.meansofidtype == null"
                                    >
                                      No Means of Identification provided
                                    </h3>
                                    <!-- <img src="../assets/images/idcard.svg" alt=""> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Means of Identification -->

                    <!-- Guarantor -->
                    <div
                      class="tab-pane fade"
                      id="guarantor"
                      role="tabpanel"
                      aria-labelledby="guarantor-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <table
                                    class="table table-borderless table-reponsive"
                                  >
                                    <tbody v-if="fulldata.gurantor != null">
                                      <tr class="row_table">
                                        <th>Guarantor Full Name::</th>
                                        <td v-if="fulldata.gurantor">
                                          {{ fulldata.gurantor.fullname }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Phone Number</th>
                                        <td v-if="fulldata.gurantor">
                                          {{ fulldata.gurantor.phone }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td v-if="fulldata.gurantor">
                                          {{ fulldata.gurantor.address }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Relationship:</th>
                                        <td v-if="fulldata.gurantor">
                                          {{ fulldata.gurantor.relationship }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Type of Id:</th>
                                        <td v-if="fulldata.gurantor">
                                          Driver's Licence
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Guarantor Form:</th>
                                        <td
                                          v-if="
                                            fulldata.gurantor != null ||
                                              fulldata.gurantor != ''
                                          "
                                        >
                                          Yes
                                        </td>
                                        <td v-else>No</td>
                                      </tr>
                                    </tbody>
                                    <h3 class="noinfo_details" v-else>
                                      No gurantor details found
                                    </h3>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Guarantor -->

                    <!-- Next Of Kin -->
                    <div
                      class="tab-pane fade"
                      id="nextofkin"
                      role="tabpanel"
                      aria-labelledby="nextofkin-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <table
                                    class="table table-borderless table-reponsive"
                                  >
                                    <tbody v-if="fulldata.nextofkin != null">
                                      <tr class="row_table">
                                        <th>Full Name:</th>
                                        <td v-if="fulldata.nextofkin">
                                          {{ fulldata.nextofkin.fullname }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Phone Number</th>
                                        <td v-if="fulldata.nextofkin">
                                          {{ fulldata.nextofkin.phone }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td v-if="fulldata.nextofkin">
                                          {{ fulldata.nextofkin.address }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Email:</th>
                                        <td v-if="fulldata.nextofkin">
                                          {{ fulldata.nextofkin.email }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Relationship:</th>
                                        <td v-if="fulldata.nextofkin">
                                          {{ fulldata.nextofkin.relationship }}
                                        </td>
                                      </tr>
                                    </tbody>
                                    <h5 v-else class="noinfo_details">
                                      No next of kin details found.
                                    </h5>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Next of kin -->

                    <!-- Occupation -->
                    <div
                      class="tab-pane fade"
                      id="occupation"
                      role="tabpanel"
                      aria-labelledby="occupation-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <table
                                    class="table table-borderless table-reponsive"
                                  >
                                    <tbody
                                      v-if="fulldata.employeddetails != null"
                                    >
                                      <tr class="row_table">
                                        <th>Employment status:</th>
                                        <td>Employed</td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Name of Employer:</th>
                                        <td>
                                          {{
                                            fulldata.employeddetails
                                              .nameoforganisation
                                          }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td>
                                          {{ fulldata.employeddetails.address }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Job title:</th>
                                        <td>
                                          {{
                                            fulldata.employeddetails.jobtitle
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Date of employment:</th>
                                        <td>
                                          {{
                                            fulldata.employeddetails
                                              .dateofemployment
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Contact Phone number:</th>
                                        <td>
                                          {{ fulldata.employeddetails.phone }}
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody
                                      v-if="
                                        fulldata.selfemployed_details != null
                                      "
                                    >
                                      <tr class="row_table">
                                        <th>Employment status:</th>
                                        <td>Self Employed</td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Name of business:</th>
                                        <td>
                                          {{
                                            fulldata.selfemployed_details
                                              .nameofbusiness
                                          }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td>
                                          {{
                                            fulldata.selfemployed_details
                                              .businessaddress
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Business sector:</th>
                                        <td>
                                          {{
                                            fulldata.selfemployed_details
                                              .businesssector
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Type of business:</th>
                                        <td>
                                          {{
                                            fulldata.selfemployed_details
                                              .typeofbusiness
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Is business registered:</th>
                                        <td
                                          v-if="
                                            fulldata.selfemployed_details
                                              .isregistered == '0'
                                          "
                                        >
                                          No
                                        </td>
                                        <td v-else>Yes</td>
                                      </tr>
                                    </tbody>
                                    <tbody v-if="fulldata.unemployed != null">
                                      <tr class="row_table">
                                        <th>Employment status:</th>
                                        <td>Unemployed</td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Last place of employment:</th>
                                        <td>
                                          {{
                                            fulldata.unemployed.lastworkplace
                                          }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Address:</th>
                                        <td>
                                          {{ fulldata.unemployed.address }}
                                        </td>
                                      </tr>

                                      <tr class="row_table">
                                        <th>Date of employment:</th>
                                        <td>
                                          {{
                                            fulldata.unemployed
                                              .dateofdisengagement
                                          }}
                                        </td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Phone number:</th>
                                        <td>{{ fulldata.unemployed.phone }}</td>
                                      </tr>
                                      <tr class="row_table">
                                        <th>Current income source:</th>
                                        <td>
                                          {{
                                            fulldata.unemployed
                                              .currentsourceofincome
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                    <h5
                                      v-if="
                                        fulldata.employeddetails == null &&
                                          fulldata.selfemployed_details ==
                                            null &&
                                          fulldata.unemployed == null
                                      "
                                    >
                                      No Occupation details found.
                                    </h5>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Occupation -->

                    <!-- Finacial status -->
                    <div
                      class="tab-pane fade"
                      id="financial"
                      role="tabpanel"
                      aria-labelledby="financial-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <h3 class="financial" v-if="fulldata">
                                    {{ fulldata.financial_status }}
                                  </h3>
                                  <h3
                                    class="financial"
                                    v-if="fulldata.financial_status == null"
                                  >
                                    No Finacial Status
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- finacial status -->
                    <!-- Finacial status -->
                    <div
                      class="tab-pane fade"
                      id="referrer"
                      role="tabpanel"
                      aria-labelledby="referrer-tab"
                    >
                      <div class="bio-holder">
                        <div class="body-holder">
                          <div class="body-wrap">
                            <div class=" body-child">
                              <div class="card cardd">
                                <div class="card-body my-card-body">
                                  <h3
                                    class="financial"
                                    v-if="fulldata.realtorcode != null"
                                  >
                                    {{ fulldata.realtorcode }}
                                  </h3>
                                  <h3
                                    class="financial"
                                    v-if="fulldata.realtorcode == null"
                                  >
                                    No realtor code
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- finacial status -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transaction-pin-modal></transaction-pin-modal>
  </div>
</template>

<script>
// import SmallNav from "./SmallNav";
import { Service } from "../store/service";
import TransactionPinModal from "././overdraft/TransactionPinModal.vue";
const Api = new Service();
import $ from "jquery";
export default {
  name: "ViewBioProfile",
  components: {
    TransactionPinModal,
    // SmallNav,
  },
  data() {
    return {
      id: "",
      userAccount: "",
      who_you: "Landlord",
      user: "",
      fulldata: "",
      fulldata: {
        emergency_details: "",
        employeddetails: "",
        gurantor: "",
        nextofkin: "",
        selfemployed_details: ""
      },
      city: "",
      state: "",
      country: "",
      city: {
        cityObject: "",
        stateObject: ""
      },
      bioOption: "editbio"
    };
  },
  // created() {
  //   this.$root.$refs.changePinModal = this;
  // },
  beforeMount() {},
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.userAccount = JSON.parse(
      localStorage.getItem("shelta-user")
    ).accounttype;
    this.getUserProfile(this.id);
  },
  methods: {
    openChangePinModal() {
      this.$root.$refs.changePinModal.triggerChangePin();
    },
    getUserProfile() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("User profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return (window.location.href = "https://myshelta.com/login");
        }
        this.user = res.data.user;
        this.fulldata = res.data.fulldata;
        this.city = res.data.user.cityObject;
        this.state = res.data.user.stateObject;
        this.country = res.data.user.countryObject;
        localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.text_header {
  padding-bottom: 1rem;
  display: flex;
  justify-content: left;
  button {
    border: 1px solid $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    // line-height: 11px;
    letter-spacing: -0.05em;
    // border-bottom: 3px solid $primary;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
    padding: 10px;
    // padding-top: 1rem;
    // padding-bottom: 0.5rem;
    cursor: pointer;
    box-shadow: none;
    outline: 0;
  }
}
.link_back {
  color: inherit;
  text-decoration: none;
}

.my_card_body {
  position: relative;
  box-sizing: border-box;
  // padding-bottom: 4rem;
}

.profile_img_circle {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  .profile_img {
    max-width: 133px;
    max-height: 133px;
    width: 133px;
    height: 133px;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
    border: 2px solid #ddd5d5;
    border-radius: 50%;
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    // display: flex;
    align-items: center;
    color: #000000;
    padding-top: 20px;
  }
}

.user_address {
  margin: auto;
  img {
    width: 15px;
    margin-right: 10px;
  }
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    align-items: center;
    color: #6c6c6c;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 0;
    margin-top: 7px;
    align-items: center;
    color: #484747;
  }
}

.new-button-option {
  background: #f5f7fe;
  padding: 8px 8px 8px 8px;
  border-radius: 50px;
  // height: 40px;
  button {
    color: #555c74;
    font-family: Gotham;
    font-size: 12px;
    border: none;
    text-transform: capitalize;
  }
}

.activeOptionss {
  color: $primary !important;
  background: $secondary !important;
  border-radius: 50px !important;
}

// Nav section to view varius details
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.tabs-nav {
  background-color: #ffffff;
  margin-left: 16px;
}
.nav-link {
  color: #856e6e;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
}
.nav-link.active {
  border-bottom: 1px solid $primary !important;
  // color: #856e6e;
  color: $black;
  border-left: none;
  border-right: none;
  border-top: none;
  font-family: Gotham-bold;
  font-style: normal;
  // font-weight: normal;
}
// Ends nav section

.body-holder {
  margin-top: 46px;
}

.cardd {
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}
.financial {
  font-family: Gotham;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
}

.noinfo_details {
  font-family: Gotham;
  font-weight: normal;
}

.profile_details_body {
  display: flex;
  justify-content: left;
}

.row_table {
  text-align: left;
  th,
  td {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    align-items: center;
    color: #000000;
  }
}

.means_of_id {
  h5 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    // display: flex;
    align-items: center;
    color: $black;
    padding-top: 20px;
    padding-bottom: 20px;
    span {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 14px;
      align-items: center;
      color: #000000;
    }
  }
  h3 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
  }

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 599px) {
  .tabs-nav {
    padding-left: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 0;
  }
  .nav-link {
    font-size: 10px;
    line-height: 40px;
    white-space: pre;
  }
  .nav-tabs {
    // border-bottom: none;
  }
  .financial {
    font-size: 20px;
    padding-top: 10px;
  }
  .row_table {
    text-align: left;
    th,
    td {
      font-size: 11px;
      line-height: 15px;
    }
  }
  .means_of_id {
    h3 {
      text-align: left;
      line-height: 28px;
      font-size: 25px;
      padding-left: 10px;
    }
  }
  .noinfo_details {
    font-size: 16px;
  }
  .card-body {
    padding: 1.25rem 5px;
  }
}
</style>
